<template>
	<v-app>
		<main class="fullh">
			<v-container fluid fill-height class="loginOverlay">
				<v-layout flex align-center justify-center>
					<v-flex xs12 sm4>
						<div style="text-align: center;" class="mb-2">
							<img src="../assets/logo-sc-blanco.svg" />
						</div>
						<v-card elevation="0" shaped>
							<v-card-text class="pt-4">
								<div>
									<v-form v-model="valid" ref="form">
										<v-text-field
											:label="$t('global.email')"
											v-model="email"
											:rules="emailRules"
											required
											outlined
											dense
											autocomplete="username"
										></v-text-field>
										<v-text-field
											:label="$t('global.password')"
											autocomplete="current-password"
											v-model="password"
											min="8"
											:type="e1 ? 'password' : 'text'"
											:rules="passwordRules"
											counter
											required
											outlined
											dense
											@keyup.enter="submit"
										></v-text-field>
										<v-layout
											justify-space-between
											class="pt-3"
										>
											<v-btn
												@click="submit"
												:class="{
													'deep-purple accent-4 white--text': valid,
												}"
												tile
												:disabled="!valid || disabled"
												:loading="loading"
												>{{$t('login.btnLogin')}}</v-btn
											>

											<v-btn
												text
												tile
												color="orange"
												@click.prevent="forgot"
												>{{$t('login.forgotPassword')}}</v-btn
											>
										</v-layout>
									</v-form>
								</div>
							</v-card-text>
						</v-card>
<!-- 						<v-btn
							block
							class="mt-5 add-button"
							color="orange accent-4"
							dark
							depresseds
							v-if="!isInstallApp"
							:disabled="btnInstallDisabled"
						>
							<v-icon medium left>
								mdi-download
							</v-icon>
							{{$t('login.btnInstallApp')}}
						</v-btn> -->
					</v-flex>
				</v-layout>
			</v-container>
		</main>

		<v-snackbar v-model="snackbar" color="info">
			{{ text }}

			<template v-slot:action="{ attrs }">
				<v-btn text v-bind="attrs" @click="snackbar = false">
					{{ $t('global.snackbar.close') }}
				</v-btn>
			</template>
		</v-snackbar>
	</v-app>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { auth } from "../plugins/firebase";
export default {
	data() {
		return {
			valid: false,
			e1: true,
			snackbar: false,
			text: "",
			password: "",
			passwordRules: [(v) => !!v || this.$t('global.rules.passwordRequired')],
			email: "",
			emailRules: [
				(v) => !!v || this.$t('global.rules.emailRequired'),
			
			],
			loading: false,
			disabled: false,
			btnInstallDisabled: true,
		};
	},
	computed: {
		isInstallApp() {
			if (window.matchMedia("(display-mode: standalone)").matches) {
				return true;
			}
			return false;
		},
	},
	methods: {
		submit(e) {
			e.preventDefault();
			this.loading = true;
			this.disabled = true;
			this.$axios
				.post("/auth/login", {
					email: this.email,
					password: this.password,
				})
				.then((resp) => {
					// Save token
					this.setUserToken(resp.data.access_token);
					// User data
					this.$axios
						.post("/auth/me")
						.then((res) => {
							this.setUserData(res.data);
							// Asignar el lenguaje
							this.$root.$i18n.locale = res.data.lang;
							this.setLang(res.data.lang);
							// Recuperar datos del chat
							this.authChat(res.data.userChatToken)
								.then(() => {
									if (res.data.currentTransportUnit === null) {
										this.$router.push({ name: "SelectUnit" });
									} else {
										this.$router.push({ name: "Home" });
									}
								})
								.catch(() => {
									throw new Error("Error firebase");
								});
						})
						.catch(() => {
							this.text = this.$t('login.txtErrorRetrievingUserData');
							this.snackbar = true;
							this.showSnackBar();
						});
				})
				.catch(() => {
					this.text = this.$t('login.txtEmailPasswordInvalid');
					this.snackbar = true;
					this.showSnackBar();
					this.loading = false;
					this.disabled = false;
				});
		},
		showSnackBar() {
			setTimeout(() => {
				this.snackbar = false;
			}, 2000);
		},
		clear() {
			this.$refs.form.reset();
		},
		forgot() {
			this.$router.push({ name: "Forgot" });
		},
		authChat(token) {
			return auth.signInWithCustomToken(token);
		},
		...mapMutations({setLang: 'setLang'}),
		...mapMutations("auth", {
			setUserToken: "setUserToken",
			setUserData: "setUserData",
		}),
		...mapGetters("auth", { isLogin: "isLogin" }),
		installApp() {
			let deferredPrompt;
			const addBtn = document.querySelector(".add-button");
			window.addEventListener("beforeinstallprompt", (e) => {
				e.preventDefault();
				deferredPrompt = e;
				this.btnInstallDisabled = false;
				addBtn.addEventListener("click", () => {
					deferredPrompt.prompt();
					deferredPrompt.userChoice.then((choiceResult) => {
						if (choiceResult.outcome === "accepted") {
							console.log("User accepted the A2HS prompt");
						} else {
							console.log("User dismissed the A2HS prompt");
						}
						deferredPrompt = null;
					});
				});
			});
		},
	},
	mounted() {
		if (this.isLogin()) {
			this.$router.push({ name: "Home" });
		}
		this.installApp();
	},
};
</script>

<style scoped>
.fullh {
	height: 100%;
	background-color: #4527a0;
}
</style>
